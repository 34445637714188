:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._miw-500px{min-width:500px;}
:root ._pos-absolute{position:absolute;}
:root ._w-10037{width:100%;}
:root ._maw-1200px{max-width:1200px;}
:root ._aspectRatio-d0t327644490{aspect-ratio:1.4990512333965844;}
:root ._pos-relative{position:relative;}
:root ._miw-0px{min-width:0px;}
:root ._mt-200px{margin-top:200px;}
:root ._ai-center{align-items:center;}
:root ._jc-2055030478{justify-content:space-evenly;}
:root ._gap-1316330145{gap:var(--space-11);}
:root ._pt-1316330145{padding-top:var(--space-11);}
:root ._pb-1316330145{padding-bottom:var(--space-11);}
:root ._maw-364px{max-width:364px;}
:root ._col-675002279{color:var(--color);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-600{font-weight:600;}
:root ._ls-905095908{letter-spacing:var(--f-21-10);}
:root ._fos-1477259397{font-size:var(--f-si-10);}
:root ._lh-1677663454{line-height:var(--f-li-10);}
:root ._ta-center{text-align:center;}
:root ._fow-500{font-weight:500;}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._col-43815456{color:var(--color-42);}